<template>
    <v-container>
        <h3>{{ $t("message.title-update-profile") }}</h3>
        <v-col class="pr-2 pb-2 mt-7" cols="12">
            <form>
                <v-row>
                    <v-col cols="12" md="2" xs="2" class="label-class">
                        <span class="label">{{$t('message.label-email')}}</span>
                    </v-col>
                    <v-col cols="12" md="10" xs="2" class="input-class">
                        {{ editProfile.email }}
                    </v-col>
                    <v-col cols="12" md="2" xs="2" class="label-class">
                        <span>{{ $t("message.label-old-password") }}</span>
                    </v-col>
                    <v-col cols="12" md="10" xs="2" class="input-class">
                        <v-text-field
                        outlined
                        v-model="editProfile.oldPassword"
                        :type="showOldPassword ? 'text' : 'password'"
                        :error-messages="oldPasswordErrors"
                        name="oldPassword"
                        autocomplete="on"
                        @input="$v.editProfile.oldPassword.$touch()"
                        @blur="$v.editProfile.oldPassword.$touch()"
                        :append-icon="showOldPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="showOldPassword = !showOldPassword"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2" xs="2" class="label-class">
                        <span>{{$t('message.password')}}</span>
                    </v-col>
                    <v-col cols="12" md="10" xs="2" class="input-class">
                        <v-text-field
                        outlined
                        v-model="editProfile.password"
                        :type="showPassword ? 'text' : 'password'"
                        :error-messages="passwordErrors"
                        name="password"
                        autocomplete="on"
                        @input="$v.editProfile.password.$touch()"
                        @blur="$v.editProfile.password.$touch()"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="showPassword = !showPassword"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2" xs="2" class="label-class">
                        <span>{{ $t("message.label-confirm-password") }}</span>
                    </v-col>
                    <v-col cols="12" md="10" xs="2" class="input-class">
                        <v-text-field
                        outlined
                        v-model="editProfile.confirmPassword"
                        :type="showConPassword ? 'text' : 'password'"
                        :error-messages="confirmPasswordErrors"
                        name="confirmPassword"
                        autocomplete="on"
                        @input="$v.editProfile.confirmPassword.$touch()"
                        @blur="$v.editProfile.confirmPassword.$touch()"
                        :append-icon="showConPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="showConPassword = !showConPassword"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2" xs="2" class="label-class">
                        <span>{{$t('message.company-name')}}</span>
                    </v-col>
                    <v-col cols="12" md="10" xs="2" class="input-class">
                        <v-text-field
                        outlined
                        required
                        
                        v-model="editProfile.company_name"
                        :error-messages="company_nameErrors"
                        @input="$v.editProfile.company_name.$touch()"
                        @blur="$v.editProfile.company_name.$touch()"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2" xs="2" class="label-class">
                        <span>{{$t('message.merchant-name')}}</span>
                    </v-col>
                    <v-col cols="12" md="10" xs="2" class="input-class">
                        <v-text-field
                        outlined
                        required
                        
                        :error-messages="merchant_nameErrors"
                        @input="$v.editProfile.merchant_name.$touch()"
                        @blur="$v.editProfile.merchant_name.$touch()"
                        v-model="editProfile.merchant_name"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2" xs="2" class="label-class">
                        <span>{{$t('message.pic-name')}}</span>
                    </v-col>
                    <v-col cols="12" md="10" xs="2" class="input-class">
                        <v-text-field
                        required
                        outlined
                        
                        v-model="editProfile.pic_name"
                        :error-messages="pic_nameErrors"
                        @input="$v.editProfile.pic_name.$touch()"
                        @blur="$v.editProfile.pic_name.$touch()"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2" xs="2" class="label-class">
                        <span>{{$t('message.phone')}}</span>
                    </v-col>
                    <v-col cols="12" md="10" xs="2" class="input-class">
                        <v-text-field
                        outlined
                        required
                        
                        v-model="editProfile.phone"
                        :error-messages="phoneErrors"
                        @input="$v.editProfile.phone.$touch()"
                        @blur="$v.editProfile.phone.$touch()"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2" xs="2" class="label-class">
                        <span>{{$t('message.postal-code')}}</span>
                    </v-col>
                    <v-col cols="12" md="10" xs="2" class="input-class">
                        <v-row align="center">
                            <v-col cols="8">
                                <v-text-field
                                v-model="editProfile.postalcode"
                                outlined
                                required
                                
                                :error-messages="postalcodeErrors"
                                @input="$v.editProfile.postalcode.$touch()"
                                @blur="$v.editProfile.postalcode.$touch()"
                                />
                            </v-col>
                            <v-col class="mb-8">
                                <v-btn
                                depressed
                                outlined
                                @click.prevent="getPostalCode()"
                                :disabled="isPostal"
                                >
                                <span>
                                    {{ $t("message.search") }}
                                </span>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="2" xs="2" class="label-class">
                        <span>{{$t('message.prefecture')}}</span>
                    </v-col>
                    <v-col cols="12" md="10" xs="2" class="input-class">
                        <v-text-field
                        required
                        outlined
                        
                        v-model="editProfile.prefecture"
                        :error-messages="prefectureErrors"
                        @input="$v.editProfile.prefecture.$touch()"
                        @blur="$v.editProfile.prefecture.$touch()"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2" xs="2" class="label-class">
                        <span>{{$t('message.city')}}</span>
                    </v-col>
                    <v-col cols="12" md="10" xs="2" class="input-class">
                        <v-text-field
                        outlined
                        
                        required
                        v-model="editProfile.city"
                        :error-messages="cityErrors"
                        @input="$v.editProfile.city.$touch()"
                        @blur="$v.editProfile.city.$touch()"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2" xs="2" class="label-class">
                        <span>{{$t('message.district')}}</span>
                    </v-col>
                    <v-col cols="12" md="10" xs="2" class="input-class">
                        <v-text-field
                        outlined
                        
                        required
                        v-model="editProfile.district"
                        :error-messages="districtErrors"
                        @input="$v.editProfile.district.$touch()"
                        @blur="$v.editProfile.district.$touch()"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2" xs="2" class="label-class">
                        <span>{{$t('message.address')}}</span>
                    </v-col>
                    <v-col cols="12" md="10" xs="2" class="input-class">
                        <v-text-field
                        outlined
                        v-model="editProfile.address"                        
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2" xs="2" class="label-class">
                        <span class="label">{{$t('message.image')}}</span>
                    </v-col>
                    <v-col cols="12" md="10" xs="2" class="">
                        <v-file-input
                        
                        accept="image/*"
                        type="file"
                        ref="myfile"
                        id="file-input"
                        outlined
                        @change="upload()"
                        v-model="editProfile.image_url"
                        ></v-file-input>
                        <v-row
                            v-if="isLoadingImg"
                            class="fill-height ma-0"
                            >
                            <v-progress-circular
                            indeterminate
                            color="blue"
                            ></v-progress-circular>
                        </v-row>

                        <v-img
                            :lazy-src="editProfile.logo_url"
                            :src="editProfile.logo_url"
                            max-height="150"
                            max-width="180px"
                        >
                        </v-img>
                    </v-col>

                </v-row>
            </form>
        </v-col>
        <div class="d-flex flex-row-reverse mt-3 me-2 mb-2" tile>
            <div class="m-2">
                <v-btn type="submit" color="primary" @click.prevent="updateProfile()">
                    <div v-if="isLoading" style="margin-right:10px">
                    <v-progress-circular
                        indeterminate
                        color="white"
                        size="11"
                        left
                    ></v-progress-circular>
                    </div>
                    {{$t('message.submit-button')}}
                </v-btn>
            </div>
            <div>
                <v-btn color="grey" class="m-2" @click="$router.go(-1)">
                    {{$t('message.back-button')}}
                </v-btn>
            </div>
        </div>
    </v-container>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, maxLength, sameAs, numeric } from "vuelidate/lib/validators";
import Swal from "sweetalert2/dist/sweetalert2.js";
export default {
    name: "UpdateProfile",
    mixins: [validationMixin],
    validations: {
        editProfile: {
            password: { required, minLength: minLength(6), maxLength: maxLength(12) },
            oldPassword: { required, minLength: minLength(6), maxLength: maxLength(12) },
            confirmPassword: { required, sameAsPassword: sameAs("password"), maxLength: maxLength(12) },
            
            postalcode: { required, maxLength: maxLength(8), numeric: numeric },
            phone: { required, maxLength: maxLength(12), numeric: numeric },
            city: { required },
            prefecture: { required },
            district: { required },
            company_name: { required, maxLength: maxLength(50) },
            merchant_name: { required, maxLength: maxLength(50) },
            pic_name: { required, maxLength: maxLength(50) },
        }
    },
    data() {
        return {
            showOldPassword: false,
            showPassword: false,
            showConPassword: false,
            isLoading: false,
            isPostal: true,
            isValid: false,
            isLoadingImg: false,
            editProfile: {
                // id: null,
                email: "",
                password: "",
                oldPassword: "",
                confirmPassword: "",
                merchant_name: "",
                company_name: "",
                pic_name: "",
                phone: "",
                address: "",
                city: "",
                district: "",
                postalcode: "",
                prefecture: "",
                logo_url: null,
                image_url: null
            }
        }
    },
    methods: {
        async dispatchProfile(){
            await this.$store.dispatch('profile_module/fetchProfile')
            let dataProfile = await this.$store.state.profile_module.profileResult

            let formatPostalcode = dataProfile.postalcode
                let convertStr = formatPostalcode.toString()
                let newPostcode = convertStr.slice(0, 3)+'-'+convertStr.slice(3)
            this.editProfile.email = dataProfile.email
            this.editProfile.merchant_name = dataProfile.merchant_name
            this.editProfile.phone = dataProfile.phone
            this.editProfile.company_name = dataProfile.company_name
            this.editProfile.address = dataProfile.address
            this.editProfile.postalcode = newPostcode
            this.editProfile.district = dataProfile.district
            this.editProfile.city = dataProfile.city
            this.editProfile.prefecture = dataProfile.prefecture
            this.editProfile.pic_name = dataProfile.pic_name
            this.editProfile.logo_url = dataProfile.logo_url
        },
        async upload() {
            this.isLoadingImg = true
            if(this.editProfile.logo_url != null) {
                await this.$store.dispatch("uploadImage_module/upload", {
                    images: this.editProfile.image_url,
                    path: "profile"
                })

                let uploadImgLink = await this.$store.state.uploadImage_module.resLinkImg
                this.editProfile.logo_url = uploadImgLink.image_url
            } else {
                this.editProfile.logo_url = null
            }
            this.isLoadingImg = false
        },
        async getPostalCode() {
            let postCode = this.editProfile.postalcode
            postCode = postCode.replace("-","")
            let res = await this.$store.dispatch("shop_module/getAddressByPostal", {
                postal: postCode
            })
            if(res.data.length > 0) {
                let resPostCode = res.data[0]
                this.editProfile.prefecture = resPostCode.pref
                this.editProfile.city = resPostCode.city
                this.editProfile.district = resPostCode.town
            } else {
                let message;
                if(typeof res.data.message === "undefined")
                    message = "postal code not found"
                else message = "API postal code error" 

                this.editProfile.prefecture = ""
                this.editProfile.city = ""
                this.editProfile.district = ""
                const Toast = Swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 1000,
                    timerProgressBar: false,
                    didOpen: (toast) => {
                        toast.addEventListener("mouseenter", Swal.stopTimer);
                        toast.addEventListener("mouseleave", Swal.resumeTimer);
                    },
                });
                    Toast.fire({
                    icon: "warning",
                    title: message,
                });
            }
        },
        updateProfile() {
            this.$v.$touch()
            this.isLoading = true

            //remove logo_url
            delete this.editProfile.image_url

            let refacPostcode = this.editProfile.postalcode
            refacPostcode = refacPostcode.replace("-","")
            this.editProfile.postalcode = refacPostcode

            if(this.editProfile.oldPassword === "" && this.editProfile.password === "" && this.editProfile.confirmPassword === "") {
                delete this.editProfile.oldPassword
                delete this.editProfile.password
                delete this.editProfile.confirmPassword

                this.$store.dispatch("profile_module/updateProfile", this.editProfile)
            } else {
                this.$store.dispatch("profile_module/updateProfile", this.editProfile)
            }
            
        },
    },
    computed: {
        oldPasswordErrors() {
            const errors = [];
            if(!this.$v.editProfile.oldPassword.$dirty) return errors
            let oldPass = this.editProfile.oldPassword
            if(oldPass === "") {
                !this.$v.editProfile.oldPassword.minLength && 
                    errors.push("The field oldPassword minLength characters 6")
            }
            return errors;
        },
        passwordErrors() {
            const errors = [];
            if(!this.$v.editProfile.password.$dirty) return errors
            let pass = this.editProfile.password
            if(pass === "") {
                !this.$v.editProfile.password.minLength && 
                    errors.push(this.$t("message.err-msg-password-minLength"))
            }
            return errors;
        },
        confirmPasswordErrors() {
            const errors = [];
            if (!this.$v.editProfile.confirmPassword.$dirty) return errors;
            let conPass = this.editProfile.confirmPassword
            if(conPass === "") {
                !this.$v.editProfile.confirmPassword.sameAsPassword &&
                    errors.push(this.$t("message.err-msg-confPass-same"));
            }
            return errors;
        },
        company_nameErrors() {
            const errors = [];
            if (!this.$v.editProfile.company_name.$dirty) return errors;
            !this.$v.editProfile.company_name.required &&
                errors.push(this.$t("message.err-msg-company_name-required"));
            !this.$v.editProfile.company_name.maxLength &&
                errors.push(this.$t("message.err-msg-company_name-maxLength"));
            return errors;
        },
        merchant_nameErrors() {
            const errors = [];
            if (!this.$v.editProfile.merchant_name.$dirty) return errors;
            !this.$v.editProfile.merchant_name.required &&
                errors.push(this.$t("message.err-msg-merchant_name"));
            !this.$v.editProfile.merchant_name.maxLength &&
                errors.push(this.$t("message.err-msg-merchant_name-maxLength"));
            return errors;
        },
        pic_nameErrors() {
            const errors = [];
            if (!this.$v.editProfile.pic_name.$dirty) return errors;
            !this.$v.editProfile.pic_name.required &&
                errors.push(this.$t("message.err-msg-pic_name-required"));
            !this.$v.editProfile.pic_name.maxLength &&
                errors.push(this.$t("message.err-msg-pic_name-maxLength"));
            return errors;
        },        
        phoneErrors() {
            const errors = [];
            if (!this.$v.editProfile.phone.$dirty) return errors;
            !this.$v.editProfile.phone.required &&
                errors.push(this.$t("message.err-msg-phone-required"));
            !this.$v.editProfile.phone.numeric &&
                errors.push(this.$t("message.err-msg-phone-numeric"));
            !this.$v.editProfile.phone.maxLength &&
                errors.push(this.$t("message.err-msg-phone-maxLength"));
            return errors;
        },
        postalcodeErrors() {
            const errors = [];
            this.isPostal = false
            if (!this.$v.editProfile.postalcode.$dirty) return errors;
            !this.$v.editProfile.postalcode.required &&
                errors.push(this.$t("message.err-postal-req"));
            !this.$v.editProfile.postalcode.maxLength && 
                errors.push(this.$t("message.err-postal-8digit"))
            // if(this.editProfile.postalcode == 0) {
            //     errors.push("postal code cant be input zero")
            // }
            // let postal = this.editProfile.postalcode
            // if(postal != null && "") {
            //     if(postal.slice(0,3) != '507') {
            //         errors.push("postal code must be input 507")
            //     }
            // }
            if(errors.length > 0) {
                this.isPostal = true
            }
            return errors;
        },
        prefectureErrors() {
            const errors = [];
            if (!this.$v.editProfile.prefecture.$dirty) return errors;
            !this.$v.editProfile.prefecture.required &&
                errors.push(this.$t("message.err-prefecture"));
            return errors;
        },
        cityErrors() {
            const errors = [];
            if (!this.$v.editProfile.city.$dirty) return errors;
            !this.$v.editProfile.city.required &&
                errors.push(this.$t("message.err-city"));
            return errors;
        },
        districtErrors() {
            const errors = [];
            if (!this.$v.editProfile.district.$dirty) return errors;
            !this.$v.editProfile.district.required &&
                errors.push(this.$t("message.err-district"));
            return errors;
        },
        // logo_urlErrors() {
        //     const errors = [];
        //     if (!this.$v.editProfile.logo_url.$dirty) return errors;
        //     !this.$v.editProfile.logo_url.required &&
        //         errors.push("The images field is required!");
        //     return errors;
        // },
    },
    created() {
        this.dispatchProfile()
    }
}
</script>

<style>

</style>